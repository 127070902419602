import styled from "styled-components";
import breakpoints from "@vertikal/e-prospera.styles.breakpoints/dist/breakpoints";
import colors, { pureColors } from "@vertikal/e-prospera.styles.colors";
import Text from "@vertikal/e-prospera.ui.text";
import Box from "@vertikal/e-prospera.ui.box";
import Tag from "@vertikal/e-prospera.ui.tag";
import Divider from "@vertikal/e-prospera.ui.divider";

export const ProfileWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 4.25rem 1rem 1rem 1rem;
  @media ${breakpoints.md} {
    padding: 3rem 2rem 2rem 2rem;
  }
  @media ${breakpoints.lg} {
    padding: 3rem 4rem 4rem 4rem;
  }
`;

export const ProfileContainer = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.25rem;
  @media ${breakpoints.md} {
    gap: 4.25rem
    padding: 0 1.5rem;
  }
`;

export const ProfilePicture = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 16px -8px rgba(0, 0, 0, 0.3), 0px 1px 4px -1px rgba(0, 0, 0, 0.25);
  border-radius: 100%;
  border: 2px solid ${pureColors.white};

  img {
    width: 56px;
    height: 56px;
    border: 2px solid ${pureColors.white};
    border-radius: 100%;
    object-fit: cover;
    flex-shrink: 0;
    @media ${breakpoints.md} {
      width: 88px;
      height: 88px;
  }
`;

export const ProfileName = styled(Text).attrs(() => ({
  variant: 'h2',
}))`
  word-break: break-word;
  padding-right: 24px;
`;

export const ProfileDivider = styled(Divider)`
  color: ${colors.gray.c200};
`