import Box from '@vertikal/e-prospera.ui.box';
import { List, ListItem } from '@vertikal/e-prospera.ui.list';
import Loader from '@vertikal/e-prospera.ui.loader';
import Paper from '@vertikal/e-prospera.ui.paper';
import Tag from '@vertikal/e-prospera.ui.tag';
import Text from '@vertikal/e-prospera.ui.text';
import { format, parseISO } from 'date-fns';
import { graphql, Link as GatsbyLink } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { API } from '../../api/api';
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs';
import GlobalSettings from '../../components/GlobalSettings';
import MainMenu from '../../components/MainMenu/MainMenu';
import { ContentWrapper } from '../../styles/business/search.styles';
import { Aside, Content, Info, Separator, VerifiedIcon } from '../../styles/entities.styles';
import {
  ProfileWrapper,
  ProfileContainer,
  ProfilePicture,
  ProfileName,
  ProfileDivider,
} from '../../styles/resident.styles';

const ResidentPublicProfilePage = (props) => {
  const {
    params: { publicId },
  } = props;

  const { t } = useTranslation();
  const [residentProfile, setResidentProfile] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const req = await fetch(`${API}/resident-register/public/residents/${publicId}`);
        const res = await req.json();
        if (!req.ok) {
          throw new Error(req.status);
        }
        setResidentProfile(res);
      } catch (error) {
        window.location.href = '/404';
      }
    }
    fetchData();
  }, [publicId]);

  const statusTagVariant = {
    ACTIVE: 'success',
    INACTIVE: 'danger',
  };

  const publicInfo = residentProfile?.publicProfileToggles;
  const residentName = `${residentProfile?.firstName} ${residentProfile?.lastName}`;

  const getAddress = (address) => {
    const { streetAddress, city, zipCode, country } = address;
    return `${streetAddress}, ${city}, ${zipCode}, ${country}`;
  };

  const displayContactSection =
    (publicInfo?.address && residentProfile.address) ||
    (publicInfo?.email && residentProfile.email) ||
    (publicInfo?.phone && residentProfile.phoneNumber);

  const displayIndustrySection =
    (publicInfo?.willWorkInPhysicalJurisdiction && residentProfile.willWorkInPhysicalJurisdiction !== null) ||
    (publicInfo?.industry && residentProfile.industry) ||
    (publicInfo?.regulatoryElection && residentProfile.regulatoryElection) ||
    (publicInfo?.regulatoryElectionSub && residentProfile.regulatoryElectionSub);

  const displayInsuranceSection =
    (publicInfo?.insurance && residentProfile.insurance?.insurance != null) ||
    (publicInfo?.riskScore && residentProfile.insurance.scoreScale);

  const displayDetailsSection =
    (publicInfo?.gender && residentProfile.gender) ||
    (publicInfo?.dateOfBirth && residentProfile.dateOfBirth) ||
    (publicInfo?.citizenship && residentProfile.citizenship) ||
    (publicInfo?.countryOfBirth && residentProfile.countryOfBirth);

  return (
    <>
      {residentProfile ? (
        <>
          <Helmet>
            <title>eProspera - {residentName}</title>
          </Helmet>
          <GlobalSettings />
          <MainMenu darkFooter={false}>
            <ContentWrapper>
              <Box style={{ alignSelf: 'center' }}>
                <Breadcrumbs theme="dark">
                  <GatsbyLink to="/">
                    <Trans>common.home</Trans>
                  </GatsbyLink>
                  <GatsbyLink to="/residency">
                    <Trans>common.residency</Trans>
                  </GatsbyLink>
                  <span>{`${residentName}`}</span>
                </Breadcrumbs>
              </Box>
              <ProfileWrapper paddingTop={3}>
                <Box maxWidth={60} style={{ width: '100%' }}>
                  <Info style={{ marginBottom: '1.5rem' }}>
                    <ProfileContainer>
                      {residentProfile.profilePicture && publicInfo.profilePicture && (
                        <ProfilePicture>
                          <img src={residentProfile.profilePicture} alt="" />
                        </ProfilePicture>
                      )}
                      <ProfileName variant="h6">{residentName}</ProfileName>
                    </ProfileContainer>
                  </Info>
                  <Content>
                    <Box marginBottom="2.5rem" maxWidth={36}>
                      <List id="resident-public-profile-info">
                        <ListItem label={t('common.status')}>
                          <Tag round borderless color={statusTagVariant[residentProfile.status]}>
                            {residentProfile?.status === 'ACTIVE' ? (
                              <Trans>common.active</Trans>
                            ) : (
                              <Trans>common.inactive</Trans>
                            )}
                          </Tag>
                        </ListItem>
                        <ListItem label={t('common.permit_number')}>{residentProfile.permitNumber}</ListItem>
                        {publicInfo?.typeOfRegistration && residentProfile.typeOfRegistration && (
                          <ListItem label={t('common.residency_type')}>
                            <Trans>
                              type_of_registration.
                              {residentProfile.typeOfRegistration}
                            </Trans>
                          </ListItem>
                        )}
                        {publicInfo?.firstRegistrationDate && residentProfile.firstRegistrationDate && (
                          <ListItem label={t('common.first_registered')}>
                            {format(parseISO(residentProfile.firstRegistrationDate), 'MM/dd/yyyy')}
                          </ListItem>
                        )}
                      </List>
                      {displayIndustrySection && (
                        <>
                          <ProfileDivider marginTop={0.625} marginBottom={0.625} />
                          <List id="resident-public-profile-industry">
                            {publicInfo?.willWorkInPhysicalJurisdiction &&
                              residentProfile.willWorkInPhysicalJurisdiction !== null && (
                                <ListItem label={t('common.regulated_industry')}>
                                  {residentProfile?.willWorkInPhysicalJurisdiction ? (
                                    <Trans>common.yes</Trans>
                                  ) : (
                                    <Trans>common.no</Trans>
                                  )}
                                </ListItem>
                              )}
                            {publicInfo?.industry && residentProfile.industry && (
                              <ListItem label={t('activity.industry')}>
                                <Trans>
                                  insurance.application.industry.
                                  {residentProfile.industry}
                                </Trans>
                              </ListItem>
                            )}
                            {publicInfo?.regulatoryElection && (
                              <ListItem label={t('common.regulatory_election')}>
                                {residentProfile.regulatoryElection ? (
                                  <Trans>
                                    regulatory_election.
                                    {residentProfile.regulatoryElection}
                                  </Trans>
                                ) : (
                                  <Trans>common.no_regulatory_election</Trans>
                                )}
                              </ListItem>
                            )}
                            {publicInfo?.regulatoryElectionSub && residentProfile.regulatoryElectionSub && (
                              <ListItem label={t('common.subnational_jurisdiction')}>
                                {residentProfile.regulatoryElectionSub}
                              </ListItem>
                            )}
                          </List>
                        </>
                      )}
                      {displayInsuranceSection && (
                        <>
                          <ProfileDivider marginTop={0.625} marginBottom={0.625} />
                          <List id="resident-public-profile-insurance">
                            {publicInfo?.insurance && residentProfile.insurance.insurance != null && (
                              <ListItem label={t('common.insurance')}>
                                {residentProfile.insurance.insurance ? (
                                  <Trans>common.covered</Trans>
                                ) : (
                                  <Trans>common.not_covered</Trans>
                                )}
                              </ListItem>
                            )}
                            {publicInfo?.riskScore && residentProfile.insurance.scoreScale && (
                              <ListItem label={t('common.risk_score')}>{residentProfile.insurance.scoreScale}</ListItem>
                            )}
                          </List>
                        </>
                      )}
                      {displayContactSection && (
                        <>
                          <ProfileDivider marginTop={0.625} marginBottom={0.625} />
                          <List id="resident-public-profile-contact">
                            {publicInfo?.address && residentProfile.address && (
                              <ListItem label={t('contact.address')}>{getAddress(residentProfile.address)}</ListItem>
                            )}
                            {publicInfo?.email && residentProfile.email && (
                              <ListItem label={t('common.email')}>{residentProfile.email}</ListItem>
                            )}
                            {publicInfo?.phone && residentProfile.phoneNumber && (
                              <ListItem label={t('common.phone')}>{`+${residentProfile.phoneNumber}`}</ListItem>
                            )}
                          </List>
                        </>
                      )}
                      {displayDetailsSection && (
                        <>
                          <ProfileDivider marginTop={0.625} marginBottom={0.625} />
                          <List id="resident-public-profile-details">
                            {publicInfo?.gender && residentProfile.gender && (
                              <ListItem label={t('personal.gender')}>
                                <Trans>personal.{residentProfile.gender}</Trans>
                              </ListItem>
                            )}
                            {publicInfo?.dateOfBirth && residentProfile.dateOfBirth && (
                              <ListItem label={t('personal.date_of_birth')}>
                                {format(parseISO(residentProfile.dateOfBirth), 'MM/dd/yyyy')}
                              </ListItem>
                            )}
                            {publicInfo?.citizenship && residentProfile.citizenship && (
                              <ListItem label={t('personal.citizenship')}>{residentProfile.citizenship}</ListItem>
                            )}
                            {publicInfo?.countryOfBirth && residentProfile.countryOfBirth && (
                              <ListItem label={t('personal.country_of_birth')}>
                                {residentProfile.countryOfBirth}
                              </ListItem>
                            )}
                          </List>
                        </>
                      )}
                      {!!residentProfile.entities.length && (
                        <>
                          <ProfileDivider marginTop={0.625} marginBottom={0.625} />
                          <List id="resident-public-profile-entities">
                            <ListItem label={t('common.connected_entities')}>
                              <Box display="flex" flexDirection="column" gap="0.25">
                                {[...residentProfile.entities]
                                  .sort((a, b) => a.fullName.localeCompare(b.fullName))
                                  .map((e) => (
                                    <Box display="flex" flexDirection="column">
                                      <Text variant="h6">{e.fullName}</Text>
                                      {!!e.organizers.length && e.organizers[0].type && (
                                        <Text variant="p-sm">
                                          <Trans>
                                            business.organizer_type.
                                            {e.organizers[0].type}
                                          </Trans>
                                        </Text>
                                      )}
                                    </Box>
                                  ))}
                              </Box>
                            </ListItem>
                          </List>
                        </>
                      )}
                    </Box>

                    <Aside>
                      <Paper style={{ padding: '2.5rem' }} borderRadius={6} id="side-content">
                        <Box justifyContent="center" display="flex" width="100%" marginBottom="32px">
                          <Box flexShrink={0}>
                            <StaticImage
                              src="./../../images/prosperazede.svg"
                              alt="Prospera Zede logo"
                              width={64}
                              height={64}
                            />
                          </Box>
                          <Separator />
                          <Box textAlign="center" paddingTop={0.25}>
                            <VerifiedIcon />
                            <Text variant="p-sm" color="success">
                              <Trans>common.verified</Trans>
                            </Text>
                          </Box>
                        </Box>
                        <Box marginBottom="16px">
                          <Text variant="p" color="gray">
                            <Trans components={{ residentName: residentName }}>residency.is_registered_to</Trans>
                          </Text>
                        </Box>
                      </Paper>
                    </Aside>
                  </Content>
                </Box>
              </ProfileWrapper>
            </ContentWrapper>
          </MainMenu>
        </>
      ) : (
        <Box height="calc(100vh - 84px)" justifyContent="center" alignItems="center" display="flex" width="100%">
          <Loader />
        </Box>
      )}
    </>
  );
};

export default ResidentPublicProfilePage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
